import './Card.css'

function Card(props) {
    return (
        <div className="card">
            <div className="card-icon-wrapper">
                <i className={props.iconClass} />
            </div>
            <span>{props.content}</span>
        </div>
    )
}

export default Card
