import { useEffect } from "react"

function Auth() {
    const redirect = () => {
        window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=1149546398048591872&redirect_uri=https%3A%2F%2Fkaguyabot.website%2Fauth%2Fcallback&response_type=token&scope=identify%20guilds'
    }

    useEffect(() => {
        redirect()
    })

    return (
        <></>
    )
}

export default Auth
