import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import axios from "axios"

import "./GuildsDashboard.css"
import Cookies from "js-cookie"

import svIcon from "../imgs/sv_icon.jpeg"

function GuildsDashboard() {
    const [guilds, setGuilds] = useState(null)
    const [pairs, setGuildPairs] = useState([])

    useEffect(() => {
        let accessToken = Cookies.get('access_token')

        if (guilds === null) {
            axios.post(
                'https://kaguya-api.discloud.app/guilds/mutual',
                {
                    accessToken: accessToken
                }
            )
            .then((res) => {
                console.log(res.data)
                setGuilds(res.data)
            })
            .catch((error) => {
                console.error(error)
            })
        }
    }, [])

    useEffect(() => {
        let pares = []

        if (guilds != null && pares.length === 0) {
            for (let i = 0; i < guilds.length; i += 2) {
                if (i + 1 < guilds.length) {
                    pares.push([guilds[i], guilds[i+1]])
                } else {
                    pares.push([guilds[i]])
                }
            }
        }

        setGuildPairs(pares)
    }, [guilds])

    return (
        <div id="guilds-content">
            <div id="guild-list-page">
                <h1>Servidores</h1>
                <p>Lista de servidores de discord compartilhados com o bot onde você possui permissões de administrador.</p>
                <div id="guilds-wrapper">
                    {pairs.map((item, index) => {
                        return (<div key={index} className="guilds-row">
                            {item.map((guild, subIndex) => {
                                return (
                                    <div key={subIndex} className="guild">
                                        {!guild.icon && (
                                            <img src={svIcon} alt='icon' />
                                        )}

                                        {guild.icon && (
                                            <img src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`} alt='icon' />
                                        )}

                                        <div>
                                            {guild.name.length > 26 && (
                                                <Link to={`/dashboard/${guild.id}`}><span>{`${guild.name.substring(0, 24)}...`}</span></Link>
                                            )}

                                            {guild.name.length < 26 && (
                                                <Link to={`/dashboard/${guild.id}`}><span>{guild.name}</span></Link>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>)
                    })}
                </div>
            </div>
        </div>
    )
}

export default GuildsDashboard
