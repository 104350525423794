import { useState, useEffect } from "react"
import { useParams, Link } from "react-router-dom"
import axios from "axios"

import DefaultButton from '../components/DefaultButton'
import AdminFields from "../components/AdminFields"
import WelcomeFields from "../components/WelcomeFields"

import "./Dashboard.css"

function Dashboard() {
    const [guild, setGuild] = useState(null)
    const { id, page } = useParams()

    useEffect(() => {
        if (guild === null || guild.guildInfo.name != id) {
            console.log(page)
            axios.get(`https://kaguya-api.discloud.app/guilds/get/${id}`)
            .then((res) => {
                console.log(res.data)
                setGuild(res.data)
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }, [])

    return (
        <div id="guild-info-content">
            <div id="tab-wrapper">
                {!guild && (
                    <>
                        <Link to="#" className="tab"><DefaultButton className="tab" content='Administração' /></Link>
                        <Link to="#" className="tab"><DefaultButton className="tab" content='Recepção' /></Link>
                        <Link to="#" className="tab"><DefaultButton className="tab" content='Auto-role' /></Link>
                    </>
                )}

                {guild && (
                    <>
                        <Link to={`/dashboard/${guild.guildInfo.id}/administracao`} className="tab"><DefaultButton className="tab" content='Administração' /></Link>
                        <Link to={`/dashboard/${guild.guildInfo.id}/recepcao`} className="tab"><DefaultButton className="tab" content='Recepção' /></Link>
                        <Link to={`/dashboard/${guild.guildInfo.id}/auto-role`} className="tab"><DefaultButton className="tab" content='Auto-role' /></Link>
                    </>
                )}
            </div>
            <div id="guild-info-container">
                <div id="guild-info-wrapper">
                    {!guild && (
                        <>
                            <h1>Servidor</h1>
                            <span>Carregando...</span>
                        </>
                    )}

                    {guild && (
                        <>
                            <h1>{guild.guildInfo.name}</h1>

                            {!page && (
                                <>
                                    <AdminFields guildConfig={guild.guildConfig} />
                                </>
                            )}

                            {page === "administracao" && (
                                <>
                                    <AdminFields guildConfig={guild.guildConfig} />
                                </>
                            )}

                            {page === "recepcao" && (
                                <>
                                    <WelcomeFields guildConfig={guild.guildConfig} />
                                </>
                            )}

                            {page === "auto-role" && (
                                <>
                                    <span>ar</span>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Dashboard
