import DefaultButton from "./DefaultButton"

import "./Fields.css"

function AdminFields({ guildConfig }) {
    return (
        <>
            <p>Configurações da parte de administração do seu servidor, para comandos de banimento, expulsão e mute de membros, além da função de logs, que mostra todas as alterações feitas por membros no servidor.</p>

            <form>
                <div className="input-wrapper">
                    <label>Canal de logs</label>
                    <select name="log-channel" id="log-channel-select">
                        <option value="null">Desativado</option>
                    </select>
                </div>

                <div className="input-wrapper">
                    <label>Mensagem privada de banimento</label>
                    <textarea name="ban-msg" placeholder="Mensagem de banimento" content={() => {
                        return guildConfig.banDmMessage ? guildConfig.banDmMessage : ""
                    }} />
                    <span className="field-desc">{"Placeholders: {reason} {punisher}"}</span>
                </div>

                <div className="input-wrapper">
                    <label>Mensagem privada de expulsão</label>
                    <textarea name="kick-msg" placeholder="Mensagem de expulsão" content={() => {
                        return guildConfig.kickDmMessage ? guildConfig.kickDmMessage : ""
                    }} />
                    <span className="field-desc">{"Placeholders: {reason} {punisher}"}</span>
                </div>

                <div className="input-wrapper">
                    <label>Mensagem privada de mute</label>
                    <textarea name="mute-msg" placeholder="Mensagem de mute" content={() => {
                        return guildConfig.muteDmMessage ? guildConfig.muteDmMessage : ""
                    }} />
                    <span className="field-desc">{"Placeholders: {reason} {punisher}"}</span>
                </div>

                <div style={{margin: "20px"}}>
                    <DefaultButton content="Salvar"/>
                </div>
            </form>
        </>
    )
}

export default AdminFields
