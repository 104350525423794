import './Home.css';
import DefaultButton from '../components/DefaultButton';
import Card from '../components/Card';

function Home() {
    return (
        <div id="home-content">
            <div id="left-container">
            </div>

            <div id="right-container">
                <div id="main-infobox">
                    <h1>O bot ideal</h1>
                    <span>para seu servidor de discord</span>
                    <div className="bttn-wrapper">
                        <DefaultButton href='#' content='Invite' />
                    </div>
                </div>

                <div id="cards-wrapper">
                    <Card iconClass='bx bx-cog' content='Gerenciamento' />
                    <Card iconClass='bx bx-music' content='Música' />
                    <Card iconClass='bx bxs-user' content='Auto-role' />
                </div>
            </div>
        </div>
    )
}

export default Home
