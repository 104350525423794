import { useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function PrivateRoute({ element }) {
    const accessToken = Cookies.get('access_token')

    if (!accessToken) {
        window.location.href = 'https://kaguyabot.website/auth'
        return null
    }

    return element
}

export default PrivateRoute
