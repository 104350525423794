import Cookies from "js-cookie"
import { useEffect } from "react"

function Logout({ setUserFunc }) {
    useEffect(() => {
        setUserFunc(null)

        let token = Cookies.get('access_token')

        if (token) {
            Cookies.remove('access_token')
        }

        window.location.href = 'https://kaguyabot.website/'
    })

    return (<></>)
}

export default Logout
