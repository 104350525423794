import { Link } from 'react-router-dom'

import './Header.css'
import headerImg from '../imgs/header-img.png'
import DefaultButton from './DefaultButton'

function Header(props) {
    const dropMenuChange = () => {
        let menu = document.querySelector('#logout-drop')

        if (menu.classList.contains('show')) {
            menu.classList.remove('show')
        } else {
            menu.classList.add('show')
        }
    }

    return (
        <div id="desktop-header">
            <div className="header-wrapper" id="left-wrapper">
                <img src={headerImg} id='bot-img' />
                <Link to='/'><span id='bot-name'>Kaguya Bot</span></Link>
            </div>

            <div className="header-wrapper" id="center-wrapper">
                <a href="#">Commands</a>
                <a href="#">Support</a>
                <Link to="/dashboard">Dashboard</Link>
            </div>

            <div className="header-wrapper" id="right-wrapper">
                {props.user === null && (
                    <Link to='/auth'><DefaultButton content='Login'/></Link>
                )}

                {props.user !== null && (
                    <>
                        <span id="profile-name">{props.user.global_name}</span>
                        <img id='user-avatar' onClick={dropMenuChange} src={`https://cdn.discordapp.com/avatars/${props.user.id}/${props.user.avatar}.png`} alt="image" />
                        <div id="logout-drop">
                            <Link to='/logout'><span>Logout</span></Link>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Header
