import "./Fields.css"

function WelcomeFields({ guildConfig }) {
    return (
        <>
            <p>Configuração dos chats e mensagens de boas-vindas e despedida do servidor.</p>

            <form>
                <div className="input-wrapper">
                    <label>Canal de boas-vindas</label>
                    <select name="welcome-channel" id="welcome-channel-select">
                        <option value="null">Desativado</option>
                    </select>
                </div>

                <div className="input-wrapper">
                    <label>Mensagem boas-vindas</label>
                    <textarea name="join-msg" placeholder="Mensagem de boas-vindas" content={() => {
                        return guildConfig.welcomeMessage ? guildConfig.welcomeMessage : ""
                    }} />
                    <span className="field-desc">{"Placeholders: {user}"}</span>
                </div>

                <div className="input-wrapper">
                    <label>Canal de despedida</label>
                    <select name="goodbye-channel" id="goodbye-channel-select">
                        <option value="null">Desativado</option>
                    </select>
                </div>

                <div className="input-wrapper">
                    <label>Mensagem boas-vindas</label>
                    <textarea name="leave-msg" placeholder="Mensagem de despedida" content={() => {
                        return guildConfig.goodbyeMessage ? guildConfig.goodbyeMessage : ""
                    }} />
                    <span className="field-desc">{"Placeholders: {user}"}</span>
                </div>
            </form>
        </>
    )
}

export default WelcomeFields
