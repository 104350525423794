import './Footer.css'
import footerImg from '../imgs/footer-img.png'

function Footer() {
    return (
        <footer className='footer'>
            <div id="footer-wrapper">
                <img src={footerImg} height='80px' width='80px' />
                <span>Todos os direitos da personagem "Kaguya Shinomiya" pertencem a Aka Akasaka.</span>
            </div>
        </footer>
    )
}

export default Footer
