import { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Cookies from 'js-cookie';
import axios from 'axios';

import Header from './components/Header'
import Footer from './components/Footer';

import Home from './pages/Home';
import Auth from './pages/Auth';
import Callback from './pages/Callback';
import Logout from './pages/Logout';
import GuildsDashboard from './pages/GuildsDashboard';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './components/PrivateRoute';

import './App.css';

function App() {
	const [user, setUser] = useState(null)

    useEffect(() => {
		if (user === null) {
			let token = Cookies.get('access_token')

			if (token) {
				axios.post('https://kaguya-api.discloud.app/user', {
					token: token
				})
				.then((response) => {
					setUser(response.data)
				})
				.catch((error) => {
					console.error(error)
				})
			}
		}
    })

	return (
		<div className="App">
				<BrowserRouter>
					<Header user={user}/>
					<div id="page">
						<Routes>
							<Route exact index path='/' element={<Home />} />
							<Route exact path='/auth' element={<Auth />} />
							<Route exact path='/auth/callback' element={<Callback />} />
							<Route exact path='/logout' element={<Logout setUserFunc={setUser} />} />
							<Route exact path='/dashboard' element={<PrivateRoute element={<GuildsDashboard />} />} />
							<Route exact path='/dashboard/:id' element={<PrivateRoute element={<Dashboard />} />} />
							<Route exact path='/dashboard/:id/:page' element={<PrivateRoute element={<Dashboard />} />} />

							<Route path='/*' element={<Navigate to='/' />} />
						</Routes>
					</div>
					<Footer />
				</BrowserRouter>
		</div>
	);
}

export default App;
