import { useEffect } from "react"
import Cookies from "js-cookie"
import axios from "axios"

function Callback() {
    useEffect(() => {
        let token = new URLSearchParams(window.location.hash.substring(1)).get('access_token')

        axios.post('https://kaguya-api.discloud.app/user', {
            token: token
        })
        .then((response) => {
            Cookies.set('access_token', token)
            window.location.href = 'https://kaguyabot.website/'
        })
        .catch((error) => {
            console.error(error)
            window.location.href = 'https://kaguyabot.website/'
        })
    })

    return (
        <></>
    )
}

export default Callback
